<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "ReferentielMainView",
  mounted() {
    if (this.$route.name === "referentiel") {
      this.$router.replace({ name: "tiers" });
    }
  },
};
</script>
